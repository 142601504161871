import { render, staticRenderFns } from "./tableStateCar.vue?vue&type=template&id=0b5bf973&scoped=true&"
import script from "./tableStateCar.vue?vue&type=script&lang=js&"
export * from "./tableStateCar.vue?vue&type=script&lang=js&"
import style0 from "./tableStateCar.vue?vue&type=style&index=0&id=0b5bf973&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b5bf973",
  null
  
)

export default component.exports